<template>
    <div
        class="message d-flex align-self-start d-flex justify-content-between"
        v-if="visible || props.show"
    >
        <div class="equal-small-2 message__text">
            <slot>...</slot>
        </div>
        <div class="message__button flex-shrink-0" @click="remove()">
            <IconsWrapper size="14px" v-if="$slots.icon" class="message__icon">
                <slot name="icon"></slot>
            </IconsWrapper>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
});

const visible = ref(props.show);

function remove() {
    visible.value = false;
    emit('remove');
}

const emit = defineEmits(['remove']);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.message {
    background-color: $color-dark-03;
    border-radius: 12px;
    padding: 15px 20px 15px 20px;

    &__text {
        align-self: center;
    }

    &__button {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        margin-left: 20px;

        border-radius: 100%;
        background-color: $color-dark-04;

        cursor: pointer;
    }

    &__icon {
        margin: auto;

        :deep(svg) {
            fill: #999999;
        }
    }

    &--danger {
        .message {
            &__text {
                color: $color-danger-01;
            }

            &__icon {
                :deep(svg) {
                    fill: $color-danger-01;
                }
            }
        }
    }

    &--danger-background {
        background-color: #290700;

        .message {
            &__button {
                background-color: #530e00;
            }
        }
    }
}
</style>
